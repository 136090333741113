// libs
import React, { memo } from 'react';

// components
import Menu from './Menu';

// defs
import { ICategoryData } from 'shared/api/mega-menu/types';

interface IMenuProps {
  menuData: ICategoryData[];
}

function MenuContentWrapper({ menuData }: IMenuProps) {
  return <Menu menuData={menuData} />;
}

export default memo(MenuContentWrapper);
