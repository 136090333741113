// libs
import React, { memo, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

// defs
import { ICategoryData, VIEW_TYPES } from 'shared/api/mega-menu/types';
import { MenuItemTitle, MenuText } from './styles';

// components
import MoreMenu from 'shared/components/Header/DesktopHeaderV2/components/Menu/components/MoreItem';

// helpers
import { getTrackingURLforPrimaryCategory } from 'shared/components/Header/DesktopHeaderV2/components/Menu/helpers';

interface IMenuItemProps {
  title: string;
  actionUrl?: string;
  children_data?: ICategoryData[];
  selected: boolean;
  onSelection: (selectedItem: string) => void;
  viewType?: VIEW_TYPES;
}

const DEFAULT_CATEGORY = '';

function MenuItem({
  selected,
  title,
  actionUrl,
  children_data,
  onSelection,
  viewType,
}: IMenuItemProps) {
  const hasSecondarySubMenu = !isEmpty(children_data);
  const isClickable = !isEmpty(actionUrl) && !hasSecondarySubMenu;

  const onMouseOver = useCallback(() => {
    if (isClickable) return;

    onSelection(title);
  }, [isClickable, onSelection, title]);

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
    window.open(getTrackingURLforPrimaryCategory({ url: actionUrl }));
  };

  if (viewType === VIEW_TYPES.MORE) {
    return (
      <MoreMenu
        onFocus={onMouseOver}
        onMouseOver={onMouseOver}
        onBlur={onMouseOver}
        onMouseOut={() => onSelection(DEFAULT_CATEGORY)}
        selected={selected}
      />
    );
  }

  return (
    <MenuItemTitle
      href={getTrackingURLforPrimaryCategory({ url: actionUrl })}
      isClickable={isClickable}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      onClick={onClick}
      selected={selected}
    >
      <MenuText selected={selected}>{title}</MenuText>
    </MenuItemTitle>
  );
}

export default memo(MenuItem);
