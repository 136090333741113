//libraries
import React, { memo, useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';

//context
import { SubNavContext } from 'shared/components/SubNav';
import { useAuthModalWrapperContext } from 'shared/components/AuthModal/AuthModalWrapperContext';
import { TOASTER_TYPES, useToastContext } from 'shared/components/Toast';

//constants
import { TEXTS } from 'shared/components/Header/DesktopHeaderV2/components/HeaderActions/components/Auth/constants';
import { AUTH_URI } from 'shared/constants/uri';

//styles
import { Logout, MenuItemWrapper, Greeting, Info, Notification } from './styles';

// helpers
import { removeOMSPlatformSessionIdentifiers } from 'shared/components/AuthModal/helpers';
import { removeEmailInSessionStorage } from 'shared/components/Notify/helpers/emailHelpers';
import storage from 'shared/helpers/storage';
import { clearFreeGiftsSessionKey } from 'shared/components/CartCTA/helpers';
import { getExperimentVariant } from '@nykaa/experiment-sdk/client';

// icon
import OrdersIcon from '@nykaa/ui-components/Icons/box';
import NFA from 'shared/helpers/analytics/NFAnalytics';

// api
import { logoutUser } from 'shared/api/auth';

// constants
import { MY_ORDERS_V2 } from 'shared/constants/uri';
import { EXPERIMENT_ORDER_VARIANTS } from 'shared/components/MyAccount/components/MyAccountSections/constants';

//def
interface ILoggedInProps {
  userName: string;
  mobile: string;
  email: string;
  hideEmail: boolean;
  shouldVerifyMobile: boolean;
  shouldVerifyEmail: boolean;
}

function LoggedIn({
  userName,
  mobile,
  email,
  hideEmail,
  shouldVerifyMobile,
  shouldVerifyEmail,
}: ILoggedInProps) {
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const { doHide } = useContext(SubNavContext);
  const { showAuthModal } = useAuthModalWrapperContext();
  const { showToast } = useToastContext();
  const { isEligible: isEligibleForNewOrders, variant = '' } =
    getExperimentVariant('postorder_variant_ab');

  const goToMyOrder = () => {
    doHide();
    let link = '/sales/order/history';
    if (isEligibleForNewOrders && variant === EXPERIMENT_ORDER_VARIANTS.VARIANT_A) {
      link = MY_ORDERS_V2;
    }
    window.location.href = link;
  };

  const logout = () => {
    NFA.reset();
    doHide();
    logoutUser()
      .then((res) => {
        if (res.status === 'success') {
          // clear all user specific data from device on logout
          removeOMSPlatformSessionIdentifiers();
          removeEmailInSessionStorage(); //clearing email id in session storage on logout
          storage.clear();
          clearFreeGiftsSessionKey();
          showToast({
            type: TOASTER_TYPES.SUCCESS,
            message: res?.response?.message || TEXTS.USER_LOGGEDOUT,
          });
          window.location.reload();
        }
      })
      .catch((e) =>
        showToast({ type: TOASTER_TYPES.ERROR, message: e?.message || TEXTS.API_ERROR_MESSAGE })
      );
  };

  const clickHandler = (requiredURI: string) => {
    params.set(AUTH_URI.PARAM_KEY, requiredURI);
    const url = pathname + '?' + params.toString();
    doHide();

    showAuthModal(url);
  };

  return (
    <MenuItemWrapper>
      <Greeting>{TEXTS.HI_USER(userName)}</Greeting>
      {isEmpty(mobile) === false && <Info>{mobile}</Info>}
      {isEmpty(email) === false && hideEmail === false && <Info>{email}</Info>}

      {shouldVerifyMobile === true && (
        <Notification onClick={() => clickHandler(AUTH_URI.MOBILE_NUMBER_VERIFICATION)}>
          {TEXTS.VERIFY_MOBILE_LABEL}
        </Notification>
      )}

      {shouldVerifyEmail === true && (
        <Notification onClick={() => clickHandler(AUTH_URI.MERGE_ACCOUNTS)}>
          {TEXTS.VERIFY_EMAIL_LABEL}
        </Notification>
      )}

      <button onClick={goToMyOrder} className="sub-menu__menu-item border-bottom border-top">
        {TEXTS.ORDERS}
        <OrdersIcon />
      </button>

      <Logout onClick={logout}>{TEXTS.LOGOUT}</Logout>
    </MenuItemWrapper>
  );
}

export default memo(LoggedIn);
