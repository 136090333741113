// libs
import React, { memo, useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import useTheme from '@nykaa/ui-components/themes/hooks/useTheme';

// context
import { useGlobalSearchContext } from 'shared/components/GlobalSearch/Mobile/GlobalSearchContext';
import { useKeyboardEventContext } from 'shared/components/GlobalSearch/components/KeyboardWrapper/KeyboardContext';

// constants
import { TEXTS } from './constants';
import { SEARCH_TYPE } from 'shared/components/GlobalSearch/constants';
import { SEARCH_URL } from 'shared/constants/uri';

// styles
import { SearchWidgetContainer, SearchInput, BackIcon, ClearButton, SearchIcon } from './styles';

// global components
import { ISearchInputRef } from 'shared/components/SearchInput';

// helpers
import {
  generateTrackingURLForSearch,
  addToRecentSearch,
  trackSearchCall,
  trackAutosuggest,
} from 'shared/components/GlobalSearch/helpers';

// defs
import { ISearchWidgetProps } from './types';
import { Theme } from '@nykaa/ui-components/themes/types';

// Icon
import ArrowLeftIcon from '@nykaa/ui-components/Icons/arrow-left';
import CloseIcon from '@nykaa/ui-components/Icons/circle-x-filled';

const VOID_FUNCTION = () => {
  /* DO NOTHING */
};

const SEARCH_ICON_SIZE = '24px';
const CLOSE_ICON_SIZE = '20px';

function SearchWidget({
  initialSearchTerm,
  callAutoSuggestService,
  isBackIconVisible,
  maxRecentSearches,
  autoFocus = false,
  className = '',
  placeHolder = TEXTS.PLACEHOLDER,
  setMenuVisible = VOID_FUNCTION,
  showSearchIcon = false,
}: ISearchWidgetProps) {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [canShowClear, setShowClear] = useState(initialSearchTerm.length > 0);
  const searchRef = useRef<ISearchInputRef>(null);
  const { hideSearchPanel } = useGlobalSearchContext();
  const { triggerSearchEvent, currentIndex } = useKeyboardEventContext();
  const theme: Theme = useTheme();

  const clear = () => {
    if (!searchRef.current) {
      return;
    }

    searchRef.current.setValue('');
  };

  const onChange = debounce((term: string) => {
    setShowClear(term.length > 0);
    setSearchTerm(term.trim());
  }, 300);

  const onSearchSubmit = (term: string, searchType?: string) => {
    const searchFinalTerm = term.trim();
    if (searchFinalTerm === '') {
      return;
    }
    addToRecentSearch(
      {
        suggestionWord: searchFinalTerm,
        url: `${SEARCH_URL}?q=${encodeURIComponent(searchFinalTerm)}`,
        suggestionSubtitle: '',
        type: SEARCH_TYPE.MANUAL,
      },
      maxRecentSearches
    );
    trackSearchCall({
      typedSearchTerm: '',
      searchTerm: searchFinalTerm,
      searchType: SEARCH_TYPE.MANUAL,
    });
    hideSearchPanel();
    // open search results in same tab, mobile won't use, since a local copy is created there
    const redirectUrl = generateTrackingURLForSearch({
      url: `${SEARCH_URL}?q=${encodeURIComponent(searchFinalTerm)}`,
      searchType: searchType || SEARCH_TYPE.MANUAL,
      internalSearchTerm: searchFinalTerm,
      typedSearchTerm: searchFinalTerm,
    });

    window.location.href = redirectUrl;
  };

  const onSearchSubmitWrapper = (searchFinalTerm: string, searchType?: string) => {
    if (currentIndex === -1) {
      onSearchSubmit(searchFinalTerm, searchType);
    } else {
      triggerSearchEvent(true);
    }
  };

  useEffect(() => {
    callAutoSuggestService(searchTerm).then((payload: any) => {
      if (payload.suggestions && payload.suggestions.length) {
        trackAutosuggest({ suggestions: payload.suggestions, searchTerm });
      }
    });
    if (searchTerm === '') {
      setShowClear(false);
    }
  }, [callAutoSuggestService, searchTerm]);

  return (
    <SearchWidgetContainer className={className}>
      {showSearchIcon && <SearchIcon size={SEARCH_ICON_SIZE} color={theme.colors.textSecondary} />}
      {isBackIconVisible && (
        <BackIcon
          as="button"
          onClick={hideSearchPanel}
          data-test="headersearch-back-btn"
          aria-label={TEXTS.GO_BACK}
        >
          <ArrowLeftIcon />
        </BackIcon>
      )}

      {/* eslint-disable jsx-a11y/no-autofocus */}
      <SearchInput
        onChange={onChange}
        onFocus={() => setMenuVisible(true)}
        onSubmit={onSearchSubmitWrapper}
        ref={searchRef}
        showIcon={false}
        placeHolder={placeHolder}
        autoFocus={autoFocus}
        data-test="headersearch-input"
        initialValue={initialSearchTerm}
      />
      {canShowClear && (
        <ClearButton
          onClick={(e) => clear(e)}
          aria-label={TEXTS.CLEAR}
          data-test="headersearch-clear-btn"
        >
          <CloseIcon size={CLOSE_ICON_SIZE} color={theme.colors.textSecondary} />
        </ClearButton>
      )}
    </SearchWidgetContainer>
  );
}

export default memo(SearchWidget);
